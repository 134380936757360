import React from "react";
import {ProductData} from "../Slideshow/Slideshow";
import styles from "./ProductInformation.module.css";
import Button from "../Button/Button";

interface Props {
    item: ProductData;
}

const ProductInformation: React.FC<Props> = ({item}) => {
    return (
        <div key={item.name} className={styles.wrapper}>
            <h2>{item.name}</h2>
            <h3>{item.brand}</h3>
            <p>{item.description}</p>
            <ul>{item.mainFeatures.map((item, key) => <li key={key}>{item}</li>)}
            </ul>
            <div className={styles.price}>{item.price.withVat} Kč</div>
            <div className={styles.buttons}>
                <Button title='Koupit'
                        href={item.eshopLink}/>
                {item.onShowRoom && (
                    <Button title='Chci testovací jízdu' outline
                            href='https://www.gepex.cz/segway-kolobezka-ninebot-kickscooter-e2-e/'/>
                )}
            </div>
            <div className={styles.controls}>
                <div className={styles.comparison}><img src="/icons/scale-unbalanced-regular.svg"
                                                        alt='comparison'/> Srovnání modelů</div>
                <div className={styles.comparison}>Fotogalerie</div>
                <div className={styles.comparison}>Kompletní specifikace</div>
            </div>
        </div>
    )
}

export default ProductInformation;