import React from 'react';
import styles from './Header.module.css';

const Header: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <img className={styles.logotype} src="/images/logotype.svg" alt='Elektrické koloběžky segway'/>
            <div className={styles.navigation}>
                <a href="#" className={styles.link}>Showroom</a>
                <a href="#" className={styles.link}>Srovnání modelů</a>
                <a href="#" className={styles.link}>Kontakt</a>
            </div>
        </div>
    )
}

export default Header;