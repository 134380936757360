import React from "react";
import {Category} from "../Slideshow/Slideshow";
import styles from "./CategorySelection.module.css";

interface Props {
    categories: Category[];
    activeCategoryKey: string;
    onSelect: (category: Category) => void;
}

const CategorySelection: React.FC<Props> = ({categories, activeCategoryKey, onSelect}) => {
    return (
        <div className={styles.wrapper}>
            {categories.map((category) => <div onClick={() => onSelect(category)} key={category.key} className={[styles.item, category.key !== activeCategoryKey ? styles.outline : ''].join(" ")}>{category.name}</div>)}
        </div>
    )
}

export default CategorySelection;