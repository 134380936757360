import React from 'react';
import styles from './Button.module.css';

interface Props {
    href?: string;
    onClick?: () => void;
    title: string;
    className?: string;
    outline?: boolean;
}

const Button: React.FC<Props> = ({title, href, onClick, className, outline}) => {

    const finalClassName = [className, styles.wrapper, outline ? styles.outline : ''].join(" ");

    if (href) {
        return (
            <a className={finalClassName} href={href}>{title}</a>
        )
    }

    return <div className={finalClassName} onClick={onClick}>{title}</div>
}

export default Button;