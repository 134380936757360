import React, {useMemo} from 'react';
import {ProductData} from "../Slideshow/Slideshow";
import styles from "./Comparison.module.css";
import Button from "../Button/Button";

interface Props {
    items: ProductData[]
}

const paramenters: { label: string; key: string; }[] = [
    {
        label: "",
        key: "image"
    },
    {
        label: "",
        key: "name"
    },
    {
        label: "",
        key: "buy"
    },
    {
        label: "Maximální dojezd",
        key: "maxRange"
    },
    {
        label: "Maximální rychlost",
        key: "maxSpeed"
    },
    {
        label: "Maximální nostnost",
        key: "maxLoadCapacity"
    },
    {
        label: "Maximální stoupání",
        key: "maxClimb"
    },
    {
        label: "Výkon motoru",
        key: "enginePower"
    },
    {
        label: "Kapacita baterie",
        key: "batteryCapacity"
    },
    {
        label: "Váha",
        key: "weight"
    },
    {
        label: "Brzdový systém",
        key: "breakSystem"
    },
    {
        label: "Umístění motoru",
        key: "enginePlacement"
    },
    {
        label: "Jízdní režimy",
        key: "ridingModes"
    },
    {
        label: "Obsah balení",
        key: "packageContent"
    },
    {
        label: "Podpora Apple FindMy",
        key: "appleFindMySupport"
    },
    {
        label: "Blinkry",
        key: "blinkers"
    },
    {
        label: "Odolnost proti vodě",
        key: "waterResistance"
    },
    {
        label: "Odpružení",
        key: "suspension"
    },
    {
        label: "Světla",
        key: "lights"
    },
    {
        label: "Typ pneumatik",
        key: "tireType"
    },
    {
        label: "Elektrický tlakson",
        key: "electricHorn"
    },
    {
        label: "Kontrola trakce",
        key: "tractionControl"
    },
]

interface TableRow {
    columns: React.ReactElement[];
}

const Comparison: React.FC<Props> = ({items}) => {

    const data: TableRow[] = useMemo(() => {
        const tempRows: TableRow[] = [];
        paramenters.forEach((param,) => {
            const tempColumns: React.ReactElement[] = [<>{param.label}</>];
            items.forEach(item => {
                let value: string | React.ReactElement = '-'
                switch (param.key) {
                    case "name":
                        value = <div className={styles.name}>{item.name}</div>;
                        break;
                    case "image":
                        value = <div className='flex justify-center'><img width="150px" src={item.mainImage} alt={item.name}/></div>
                        break;
                    case "buy":
                        value = <a className={styles.buy} href={item.eshopLink}>Koupit</a>
                        break;
                    default:
                        value = <>{item.features.find(feature => feature.key === param.key)?.value ?? '-'}</>;

                }
                tempColumns.push(value);
            })
            tempRows.push({columns: tempColumns});
        })
        return tempRows;
    }, [items])

    return (
        <div className={styles.wrapper}>
            <table className="table-auto w-full">
                <tbody>
                {data.map((row) => {
                    return (
                        <tr>
                            {row.columns.map(column => {
                                return <td>{column}</td>
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

export default Comparison;