import React, {useEffect, useState} from 'react';
import styles from './Slideshow.module.css'
import ProductPreview from "../ProductPreview/ProductPreview";
import Header from "../Header/Header";
import ProductInformation from "../ProductInformation/ProductInformation";
import CategorySelection from "../CategorySelection/CategorySelection";
import data from "../../assets/data.json";
import Comparison from "../Comparison/Comparison";

export interface ProductData {
    name: string;
    brand: string;
    description: string;
    mainFeatures: string[];
    features: { key: string; value: string; }[];
    eshopLink: string;
    mainImage: string;
    images: string[];
    price: {
        withVat: number;
    };
    onShowRoom: boolean;
}

export interface Category {
    name: string;
    key: string;
    items: ProductData[];
}

const categories: Category[] = data.items as Category[];

const Slideshow: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [activeCategory, setActiveCategory] = useState<Category>();

    useEffect(() => {
        const defaultCategory = categories?.find(item => item.key === "forAdults");
        if (defaultCategory) {
            setActiveCategory(defaultCategory);
        }
    }, []);

    const handleNextScooter = () => {
        const nextIndex = activeIndex + 1;
        if (activeCategory?.items[nextIndex]) {
            setActiveIndex(nextIndex);
        } else {
            setActiveIndex(0);
        }
    }

    const handleSetCategory = (category: Category) => {
        setActiveCategory(category);
        setActiveIndex(0);
    }

    if (!activeCategory) {
        return <></>
    }

    return (
        <div className='container mx-auto'>
            <div className={styles.wrapper}>
                <Header/>
                <div className={styles.content}>
                    <div className={styles.item}>
                        <div>
                            <CategorySelection onSelect={handleSetCategory} categories={categories}
                                               activeCategoryKey={activeCategory.key}/>
                            {activeCategory.items[activeIndex] &&
                                <ProductInformation item={activeCategory.items[activeIndex]}/>}
                        </div>
                    </div>
                    <div className={styles.item}>
                        {activeCategory.items[activeIndex]?.mainImage && <ProductPreview onNext={handleNextScooter}
                                                                                        image={activeCategory.items[activeIndex].mainImage}/>}
                    </div>
                </div>
            </div>
            <Comparison items={activeCategory.items}/>
        </div>
    )

}

export default Slideshow;