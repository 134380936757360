import React from 'react';
import Slideshow from "../../components/Slideshow/Slideshow";
import Comparison from "../../components/Comparison/Comparison";

const Home: React.FC = () => {
    return (
        <div>
            <Slideshow/>
        </div>
    )
}

export default Home;